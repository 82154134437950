import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "../style/OrderHistory.css";
import {
  Alert,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

export default function OrderHistory({ user }) {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [openWarrantyDialog, setOpenWarrantyDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [warrantyReason, setWarrantyReason] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      console.log(`[${new Date().toISOString()}] Người dùng chưa đăng nhập, không lấy lịch sử mua hàng`);
      navigate("/");
      return;
    }

    const fetchOrders = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log(`[${new Date().toISOString()}] Không tìm thấy token trong localStorage, chuyển hướng đến /`);
        navigate("/");
        return;
      }

      console.log(`[${new Date().toISOString()}] Bắt đầu lấy lịch sử mua hàng từ API`);
      try {
        const response = await fetch("https://savinashop.com/api/orders/user/orders", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          const errorData = await response.json();
          if (response.status === 401 || response.status === 403) {
            console.log(`[${new Date().toISOString()}] Phiên đăng nhập hết hạn hoặc không có quyền, chuyển hướng đến /`);
            localStorage.removeItem("token");
            throw new Error("Phiên đăng nhập hết hạn hoặc bạn không có quyền truy cập!");
          }
          throw new Error(errorData.message || `Lỗi lấy lịch sử mua hàng: ${response.status}`);
        }

        const data = await response.json();
        console.log(`[${new Date().toISOString()}] Dữ liệu đơn hàng:`, JSON.stringify(data, null, 2));
        setOrders(Array.isArray(data) ? data.sort((a, b) => new Date(b.date) - new Date(a.date)) : []);

        // Lấy lịch sử bảo hành cho tất cả sản phẩm
        const warrantyPromises = data.flatMap(order =>
          order.items.map((item, index) =>
            fetch(`https://savinashop.com/api/warranty?orderId=${order._id}&itemIndex=${index}`, {
              headers: { Authorization: `Bearer ${token}` },
            }).then(res => res.json())
          )
        );
        const warrantyResults = await Promise.all(warrantyPromises);
        setOrders(prevOrders =>
          prevOrders.map((order, orderIdx) => ({
            ...order,
            items: order.items.map((item, itemIdx) => ({
              ...item,
              warrantyHistory: warrantyResults[orderIdx * order.items.length + itemIdx] || [],
            })),
          }))
        );
      } catch (error) {
        console.error(`[${new Date().toISOString()}] Lỗi khi lấy lịch sử mua hàng: ${error.message}`);
        setError(error.message);
        toast.error(error.message || "Đã có lỗi xảy ra khi lấy lịch sử mua hàng!", {
          duration: 5000,
          position: "bottom-center",
          style: {
            background: "#dc3545",
            color: "#fff",
            fontSize: "1rem",
            fontWeight: 500,
            borderRadius: "8px",
            padding: "12px 20px",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
            minWidth: "300px",
            maxWidth: "500px",
          },
        });
        if (error.message.includes("Phiên đăng nhập hết hạn")) {
          navigate("/");
        }
      }
    };

    fetchOrders();
  }, [user, navigate]);

  const handleOpenWarrantyDialog = (orderId, item, index, orderDate) => {
    setSelectedItem({ orderId, item, index, orderDate });
    setWarrantyReason("");
    setOpenWarrantyDialog(true);
  };

  const handleCloseWarrantyDialog = () => {
    setOpenWarrantyDialog(false);
    setSelectedItem(null);
    setWarrantyReason("");
  };

  const handleSubmitWarranty = async () => {
    if (!warrantyReason.trim()) {
      toast.error("Vui lòng nhập lý do bảo hành!", {
        duration: 4000,
        position: "bottom-center",
        style: {
          background: "#dc3545",
          color: "#fff",
          fontSize: "1rem",
          fontWeight: 500,
          borderRadius: "8px",
          padding: "12px 20px",
        },
      });
      return;
    }

    const token = localStorage.getItem("token");
    try {
      console.log(`[${new Date().toISOString()}] Gửi yêu cầu bảo hành: orderId=${selectedItem.orderId}, itemIndex=${selectedItem.index}`);
      const response = await fetch("https://savinashop.com/api/warranty", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          orderId: selectedItem.orderId,
          itemIndex: selectedItem.index,
          reason: warrantyReason,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Lỗi gửi yêu cầu bảo hành!");
      }

      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === selectedItem.orderId
            ? {
                ...order,
                items: order.items.map((item, idx) =>
                  idx === selectedItem.index
                    ? {
                        ...item,
                        itemStatus: "Đang xử lý bảo hành",
                        warrantyHistory: [...(item.warrantyHistory || []), {
                          reason: warrantyReason,
                          status: "Đang xử lý bảo hành",
                          response: "",
                          createdAt: new Date().toISOString(),
                        }],
                      }
                    : item
                ),
              }
            : order
        )
      );

      toast.success("Yêu cầu bảo hành đã được gửi thành công!", {
        duration: 4000,
        position: "bottom-center",
        style: {
          background: "#28a745",
          color: "#fff",
          fontSize: "1rem",
          fontWeight: 500,
          borderRadius: "8px",
          padding: "12px 20px",
        },
      });

      handleCloseWarrantyDialog();
    } catch (error) {
      console.error(`[${new Date().toISOString()}] Lỗi gửi yêu cầu bảo hành: ${error.message}`);
      toast.error(error.message || "Đã có lỗi xảy ra khi gửi yêu cầu bảo hành!", {
        duration: 4000,
        position: "bottom-center",
        style: {
          background: "#dc3545",
          color: "#fff",
          fontSize: "1rem",
          fontWeight: 500,
          borderRadius: "8px",
          padding: "12px 20px",
        },
      });
    }
  };

  if (!user) {
    return (
      <Box sx={{ my: 5, textAlign: "center" }}>
        <Typography variant="body1" color="textSecondary">
          Vui lòng đăng nhập để xem lịch sử mua hàng.
        </Typography>
      </Box>
    );
  }

  return (
    <Box component="main" sx={{ my: 5, mx: "auto", maxWidth: "1200px" }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
        role="heading"
        aria-level="1"
      >
        Lịch sử mua hàng
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 4 }} role="alert">
          <i className="bi bi-exclamation-triangle me-2"></i>
          {error}
        </Alert>
      )}

      {orders.length === 0 ? (
        <Typography variant="body1" align="center" color="textSecondary">
          Bạn chưa có đơn hàng nào.
        </Typography>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          {orders.map((order) => (
            <Card key={order._id} className="order-history-card shadow-sm">
              <CardContent sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Đơn hàng ngày {new Date(order.date).toLocaleString()}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Tổng tiền: <strong>{order.total.toLocaleString()} point</strong>
                </Typography>
                {order.items.map((item, index) => (
                  <Box key={index} className="order-item">
                    <CardMedia
                      component="img"
                      image={item.image ? `https://savinashop.com${item.image}` : "https://placehold.co/80x80"}
                      alt={`Hình ảnh sản phẩm ${item.name}`}
                      onError={(e) => (e.target.src = "https://placehold.co/80x80?text=Image+Not+Found")}
                    />
                    <Box className="order-item-details">
                      <Typography variant="body1">
                        {item.name} - {item.quantity} x {item.variant.price.toLocaleString()} point
                      </Typography>
                      {item.note && (
                        <Typography variant="body2" color="textSecondary" component="p">
                          Ghi chú: {item.note}
                        </Typography>
                      )}
                      {item.deliveryInfo && (
                        <Typography variant="body2" color="textSecondary" component="p">
                          Thông tin gửi: {item.deliveryInfo}
                        </Typography>
                      )}
                      {item.rejectionReason && (
                        <Typography variant="body2" color="error" component="p">
                          Lý do từ chối: {item.rejectionReason}
                        </Typography>
                      )}
                      <Typography
                        className={`status-tag status-${
                          item.itemStatus === "Đang xử lý"
                            ? "pending"
                            : item.itemStatus === "Thành công"
                            ? "success"
                            : item.itemStatus === "Thất bại"
                            ? "failed"
                            : item.itemStatus === "Đang xử lý bảo hành"
                            ? "warranty-pending"
                            : item.itemStatus === "Đã được bảo hành"
                            ? "warranty-success"
                            : "warranty-failed"
                        }`}
                      >
                        Trạng thái: {item.itemStatus}
                      </Typography>
                      {item.warrantyHistory && item.warrantyHistory.length > 0 && (
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="subtitle2" gutterBottom>Lịch sử bảo hành:</Typography>
                          {item.warrantyHistory.map((warranty, idx) => (
                            <Box key={warranty._id || idx} sx={{ mb: 1, pl: 2 }}>
                              <Typography variant="body2">
                                <strong>Lần {idx + 1} ({new Date(warranty.createdAt).toLocaleString()}):</strong>
                              </Typography>
                              <Typography variant="body2">Lý do: {warranty.reason}</Typography>
                              <Typography variant="body2">Trạng thái: {warranty.status}</Typography>
                              {warranty.response && (
                                <Typography variant="body2">
                                  {warranty.status === "Đã được bảo hành" ? "Nội dung bảo hành" : "Lý do từ chối"}: {warranty.response}
                                </Typography>
                              )}
                            </Box>
                          ))}
                        </Box>
                      )}
                      {(item.itemStatus === "Thành công" || item.itemStatus === "Đã được bảo hành") && (
                        <button
                          className="warranty-button"
                          onClick={() => handleOpenWarrantyDialog(order._id, item, index, order.date)}
                        >
                          Yêu cầu bảo hành
                        </button>
                      )}
                    </Box>
                  </Box>
                ))}
              </CardContent>
            </Card>
          ))}
        </Box>
      )}

      <Dialog open={openWarrantyDialog} onClose={handleCloseWarrantyDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Yêu cầu bảo hành</DialogTitle>
        <DialogContent>
          {selectedItem && (
            <>
              <Typography variant="body1" gutterBottom>
                Sản phẩm: <strong>{selectedItem.item.name}</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Biến thể: <strong>{selectedItem.item.variant.name}</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Ngày mua: <strong>{new Date(selectedItem.orderDate).toLocaleString()}</strong>
              </Typography>
              <TextField
                label="Lý do bảo hành"
                multiline
                rows={4}
                fullWidth
                value={warrantyReason}
                onChange={(e) => setWarrantyReason(e.target.value)}
                margin="normal"
                required
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWarrantyDialog} color="secondary">
            Hủy
          </Button>
          <Button onClick={handleSubmitWarranty} color="primary" variant="contained">
            Gửi yêu cầu
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}